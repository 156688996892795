//TODO public route here, or index as its all public
//then have this as a page right
import react from "react";
import {Outlet, Link} from "react-router-dom";



export default function Root({title}) {
  return (
    <react.Fragment>
      <div>
      <h1>{title}</h1>
      </div>
      {/* this causes new document request, unlik the below <button><a href="carform">click here</a></button> */}
      <div id="detail">
      <button><Link to={`forms`}>click here</Link></button>
        {/* one must tell react router where this root component should insert its children */}
        <Outlet />
      </div>
  </react.Fragment>
  );
}
