import React from "react";

//this will only really catch react related errors (e.g rendering) - so things like event related actions (like my js code to fetch tdata that might error)
//probs still needs the modal
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  /* componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  } */

  //TODO do I need this if I have already set up a fallback Render?
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <React.Fragment>
          <h1>Something went wrong.</h1>
          <p>{this.errorInfo}</p>
        </React.Fragment>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
