import React from "react";

//children are a special prop - if you nest the jsx  <FormWrappper>within the tags like so</FormWrappper>, then you can pick these up
export default function FormWrapper({title, children}){
  return (
  <React.Fragment>
      <div className="emission-input-form">
          <h2 className="form-header">
            {title}
          </h2>
        {children}
      </div>
  </React.Fragment>
  )
}
