import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import ErrorModal from './ErrorModal';


export default function CarTravelDistanceForm(props){
  // const [make, setMake] = useState();
  const [haveErrors, setHaveErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const {onInputChange} = props;


  function handleDistanceChange(e){
    // setMake(e.target.value);
    // GW didn't work
    // const inputId = e.target.id;
    // const labelFor = document.querySelector(`label[for="${inputId}]`)
    const labelFor = "distance"
    console.debug(`set ${labelFor} to `,e.target.value);
    onInputChange(labelFor,e.target.value);
  }

  function handleDistanceUnitChange(e){
    // setMake(e.target.value);
    // GW didn't work
    // const inputId = e.target.id;
    // const labelFor = document.querySelector(`label[for="${inputId}]`)
    const labelFor = "distanceUnit";
    console.debug(`set ${labelFor} to `,e.target.value);
    onInputChange(labelFor,e.target.value);
  }

  return (
      <React.Fragment>
        <form >
          <section>
            <label htmlFor="distance-per-year" className="car-form">
              Distance
            <input
              type="number"
              id="distance-per-year"
              className="input input__lg"
              name="model"
              autoComplete="off"
              onChange={handleDistanceChange}
            />
            </label>
          </section>
          <section>
            <label htmlFor="distance-unit" className="car-form">
              Unit
            <select id="distance-unit" name="distance-unit" className="car-form" onChange={handleDistanceUnitChange}>
              <option value=""></option>
              <option value="miles">Miles</option>
              <option value="kilometers">Kilometers</option>
            </select>
            </label>
          </section>
          <section>
            {/* <button type="submit" className="car_form">
              Add
            </button> */}
          </section>
        </form>
        <ErrorModal showError={haveErrors} errorMessage={errorMessage}/>
    </React.Fragment>
  );
}

