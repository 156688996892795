import React, { useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { CarsApi } from '@georgewheatcroft/cars-api';


const GRAMS_IN_A_METRIC_TON = 1000000;
const MILE_IN_KM = 1.6093;

function milesToKm(miles){
  return miles * MILE_IN_KM;
}


function GetEmissionsResult(props) {
  const [emissions, setEmissions] = useState();
  useEffect(() => {
    async function fetchData() {
      try {
        const make = props?.make;
        const model = props?.model;
        const distance = props?.distance;
        const distanceUnit = props?.distanceUnit;
        //don't make request to the server when nothing has been selected
        let apiInstance = new CarsApi();
        apiInstance.carsPollutionFigureGet(make, model, (error, co2_g_per_km, response) => {
        // apiInstance.carsPollutionFigureGet('foo', 'bar', (error, data, response) => {
          if (error) {
            console.error(error);
          } else {
            console.log('API called successfully. Returned data: ' + co2_g_per_km);
            let result = 0;
            if (distanceUnit.toLowerCase() === "kilometers"){
              result = (distance * co2_g_per_km) / GRAMS_IN_A_METRIC_TON
            }else{
              result = (milesToKm(distance) * co2_g_per_km) / GRAMS_IN_A_METRIC_TON;
            }

            setEmissions(result);
          }
        });

      }catch(err){
        // console.error(`saw error when fetching car models for ${props?.make}: ${err}`);
        console.error(`saw error when fetching pollution value for inputs: ${JSON.stringify(props)}: ${err}`);
        setEmissions([]); //TODO should I ?
      }
    }
    fetchData();
  }, [props]); //crucial - need to tell it when a change should make it re-render
  return (
  <>
      {emissions} Tons of C02 Per Year!
  </>
  )

}

export default function Results() {
  const location = useLocation();
  const { formData } = location.state;
  console.log(`forms data = ${JSON.stringify(formData)}`);

  // function getEmissionsResult(input) {
  //   try {
  //     let apiInstance  = new CarsApi();
  //     // apiInstance.carsPollutionFigureGet( input.make, input.model, (error,data,response) => {
  //     apiInstance.carsPollutionFigureGet( 'foo', 'bar', (error,data,response) => {
  //         if (error) {
  //           console.error(error);
  //           return;
  //         }else {

  //           console.debug('API called successfully. Returned data: ' + data);
  //         return data;
  //       }
  //       });
  //   }catch(err){
  //       console.error(`saw error when fetching pollution value for inputs: ${input}: ${err}`);

  //   }
  // }


  return (
  <>
      <div className="forms-container">
        {/* <span>result = {getEmissionsResult(formData)}</span> */}
        <span>result = <GetEmissionsResult make={formData.make} model={formData.model} distance={formData.distance} distanceUnit={formData.distanceUnit}/>
        </span>
      </div>
  </>
  );

}
