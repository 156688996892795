import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';


//TODO this kind of works...but won't keep making new pop ups while in an error state, unless the error message changes
function ErrorModal(props) {
  const showError = props?.showError != null ? props.showError : false;
  const errorMessage = props?.errorMessage != null ? props.errorMessage : "No error message provided";
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(()=>{
      setIsModalOpen(showError);
  },[showError,errorMessage]);

  return (
    <>
      <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
        <h1>Error</h1>
        <p>An error has occurred:</p>
        <code>{errorMessage}</code>
        <div>
        <button onClick={() => setIsModalOpen(false)} className="error modal_close">Close</button>
        </div>
      </Modal>
    </>
  );
}

export default ErrorModal;
