import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import ErrorModal from './ErrorModal';
import { CarsApi } from '@georgewheatcroft/cars-api';


function CarModelSelect(props) {
  // TODO seems hard to handle knowing/enforcing what exact prop entities will be passed into function...
  // is there a way we can ensure this for components? or do we need to use a class? or do we need to just use
  // comment param docs in a javadoc style? or do we need to use typescript?....
  const [data, setData] = useState(['']);
  const [selectedValue, setSelectedValue] = useState('');
  const errorCallback = props.errorCallback;

  useEffect(() => {
    //TODO how to handle these errs better - I've 
    async function fetchData() {
      try {
        // const make = props?.make != null ? {make: props.make} : {};
        const make = props?.make;
        //don't make request to the server when nothing has been selected
        if (!make){
          console.info(`nothing selected - props.make = ${props.make}`);
          return;
        }
        let apiInstance = new CarsApi();
        apiInstance.carsModelsGet(make, (error, data, response) => {
          if (error) {
            console.error(error);
          } else {
            console.log('API called successfully. Returned data: ' + data);
            //add in the '' at the start of the array so can be blank by default
            // setData(['',...response.json()]);
            setData(['',...data]);
          }
        });

      }catch(err){
        // console.error(`saw error when fetching car models for ${props?.make}: ${err}`);
        errorCallback(`saw error when fetching car models for ${props?.make}: ${err}`);
        setData([]); //TODO should I ?
      }
    }
    fetchData();
  }, [props.make]); //crucial - need to tell it when a change should make it re-render

  function handleChange(event){
    setSelectedValue(event.target.value);
    props.onChange(event);
  }

  return (
    <select defaultValue="" value={selectedValue} onChange={handleChange}>
      {data.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ))}
    </select>
  );
}


export default function CarForm(props){
  const [make, setMake] = useState();
  const [haveErrors, setHaveErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const {onInputChange} = props;

  function handleMakeChange(e) {
    console.debug(`set make to `,e.target.value);
    setMake(e.target.value);
    onInputChange('make',e.target.value);
  }

  //TODO dry up the repeated use of these common functions used between the car forms (which are really not one form any more)
  function handleModelChange(e){
    // GW didn't work
    // const inputId = e.target.id;
    // const labelFor = document.querySelector(`label[for="${inputId}]`)
    const labelFor = "model"
    console.debug(`set ${labelFor} to `,e.target.value);
    onInputChange(labelFor,e.target.value);
  }

  function errorCallback(err){
    setHaveErrors(true);
    setErrorMessage(err);
  }

  return (
      <React.Fragment>
        <form >
          <section>
            <label htmlFor="make" className="car-form">
              Make
            <input
              type="search"
              id="make"
              list="makes"
              className="input input__lg"
              name="make"
              autoComplete="off"
              onChange={handleMakeChange}
            />
            <datalist id="makes">
              {/* TODO get this from some kind of api call */}
            <option value="Ford"></option>
            <option value="Tesla"></option>
            <option value="BMW"></option>
            <option value="Abarth"></option>
            <option value="Mercedes"></option>
            <option value="Audi"></option>
          </datalist>
            </label>
          </section>
          <section>
            <label htmlFor="model" className="car-form">
              Model
            <CarModelSelect make={make} errorCallback={errorCallback} onChange={handleModelChange}/>
            </label>
          </section>
          <section>
            {/* <button type="submit" className="car-form"> */}
            {/*   Add */}
            {/* </button> */}
          </section>
        </form>
        <ErrorModal showError={haveErrors} errorMessage={errorMessage}/>
    </React.Fragment>
  );
}

