import FormWrapper from "./FormWrapper";
import CarSelectionForm from "./CarModelSelectionForm";
import CarTravelDistanceForm from "./CarTravelDistanceForm";
import {Outlet, Link} from "react-router-dom";
import React, { useState } from 'react';
// import AirplaneTravelForm from "./AirplaneTravelForm";

// export default function Forms({FormComponents}){
export default function Forms(props) {
  /* TODO could pass in forms here... wrappedComponents = FormComponents.map((component)=>{
        <FormWrapper component={component} />
  }); */


  const [formData, setFormData] = useState({});

  const handleFormChange = (inputName, inputValue) => {
    setFormData({ ...formData, [inputName]: inputValue });
  }


  return (
    <>
      <header className="page-header">{props.title}</header>
      {/* TODO consolidate as parts of a section */}
      <div className="forms-container">
        <FormWrapper title="What car do you have?" >
          <CarSelectionForm onInputChange={handleFormChange}/>
        </FormWrapper>
        <FormWrapper title="How much do you drive every year?" >
          <CarTravelDistanceForm onInputChange={handleFormChange}/>
        </FormWrapper>
      </div>
                      {/* TODO unbodge */}
      <button><Link to={`../results`} state={{formData}}>Submit</Link></button>
      {/* <button onSubmit={() => doThings()}>Submit</button> */}
    </>
  );
}
