import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//TODO using components like pages is wrong
//see: https://www.xenonstack.com/insights/reactjs-project-structure
//-> need to put these in pages dir, then fix up
//  - ..or is this just for when using next js? 
import ErrorBoundary from "./components/ErrorBoundary";
import ErrorPage from "./components/ErrorPage";
import Root from "./routes/Root";
import Results from "./components/ResultsPage";
import Forms from "./components/FormsPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import reportWebVitals from './reportWebVitals';

const appTitle = "How much do I pollute?";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root title={appTitle}/>,
    errorElement: <ErrorPage />,
    // children:[
    //   { 
    //     path: "forms",
    //     element: <Forms />
    //   }
    // ]
  },
  {
    path: "forms",
    element: <Forms title={appTitle}/>
  },
  {
    path: "results",
    // TODO implement
    element: <Results />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ErrorBoundary
        fallbackRender = {({error, resetErrorBoundary, componentStack}) => (
          <div>
            <h1>an error occurred: {error.message}</h1>
            <button onClick={resetErrorBoundary}>Try again</button>
          </div>
        )}
      >
    <RouterProvider router={router} />
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
